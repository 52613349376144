<template> 
<div class="h-100" id="Setting"> 
  <CommonLeftRightAlertFunc ref="commonLeftRightAlertFunc" :title="title"  :content="content" :btnnameleft="btnNameLeft" :btnnameright="btnNameRight" :onfuncleft="onFuncLeft" :onfuncright="onFuncRight"/>
  <Header/>
  <body class=" bg-light h-100">
  <!-- header -->
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a @click="onBack" class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" >
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          我的設定
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>      
<!-- End header -->
 <div class="nicolespacetop"></div>
      <div class="container mt-3 mt-md-4 mb-4 mb-md-5">
      <div class="card card-body shadow-s py-2 ">
    <table class="table  table-borderless align-items-center mb-0 ">
      <tbody>
        <tr>
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/img004.png" class="avatar avatar-22 rounded-0 me-2">
              </div>
                <h6 class="mb-0  text-s text-dark">訊息設定</h6>
            </div>
          </td>
          <td class="align-middle text-right pe-1">
            <div class="form-switch">			
			<!--
              <input class="form-check-input" type="checkbox"  @click="onMessage_switch()" checked v-if="message_switch == 'Y'" >	
              <input class="form-check-input" type="checkbox"   @click="onMessage_switch()" v-else >	 -->
			  <!--<input class="form-check-input" type="checkbox" value="" id="fcustomCheck1" :checked="ischeck" v-model="status" @touchend="notShowPopUp(status)">			-->
			  <input class="form-check-input" type="checkbox" value="" id="fcustomCheck1" :checked="message_switch == 'Y'" @click="onMessage_switch" >
			  
            </div>
          </td>
        </tr>         
          <tr>
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_me_notice_32.png" class="avatar avatar-22 rounded-0 me-2">
              </div>
                <h6 class="mb-0  text-s text-dark">推播設定</h6>
            </div>
          </td>
          <td class="align-middle text-right pe-1">
            <div class="form-switch">
			<!--
              <input class="form-check-input" type="checkbox" checked  @click="onPush_switch" v-if="push_switch == 'Y'" >
              <input class="form-check-input" type="checkbox" @click="onPush_switch" v-else>	-->
			  <input class="form-check-input" type="checkbox" value="" id="fcustomCheck2" :checked="push_switch == 'Y'" @click="onPush_switch" >


			  
            </div>
          </td>
        </tr>        
          <tr>
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_me_qhistery_32.png" class="avatar avatar-22 rounded-0 me-2 ">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-s text-dark">操作說明</h6>
              </div>
            </div>
          </td>
          <td @click="onReadme" class="align-middle text-right pe-1">
            <i  class="far fa-chevron-right text-light2"></i>
          </td>
        </tr>     
          <tr v-if="isDeleteAccount">
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/img001.png" class="avatar avatar-22 rounded-0 me-2 ">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-s text-dark">刪除帳號</h6>
              </div>
            </div>
          </td>
          <td @click="onConfirmDeleteAccount" class="align-middle text-right pe-1">
            <i class="fa-solid fa-chevron-right text-light2"></i>
          </td>
        </tr>          
          <tr>
          <td class="ps-0 ">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_nav_setting.png" class="avatar avatar-22 rounded-0 me-2 ">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0  text-s text-dark">版本說明</h6>
              </div>
            </div>
          </td>
          <td class=" text-right  pe-1">
             <h6 class="mb-0  text-s text-dark text-bold">{{version}}</h6>
          </td>
        </tr>          
      </tbody>
    </table>
    </div>
    </div>
	 
   <div class="container text-center ">
      <button @click="onSignOut" type="button" class="btn btn-lg  btn-success rounded-pill w-95  text-s mx-auto">登出</button>
    </div>     
	 
  </body>
	<Footer/>  
    </div>  
</template>

<script>
// @ is an alias to /src
//import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue' 

import Vue from 'vue'
import ScrollLoader from 'vue-scroll-loader'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import CommonLeftRightAlertFunc from '@/components/CommonLeftRightAlertFunc.vue'




export default {
  name: 'Setting',
  components: {
    Footer,
    CommonLeftRightAlertFunc,
  },
  data () {
    return { 
      // params
      setDebugMode:false ,      // 該功能的除錯模式
      apiDebug:false ,          // API 除錯模式
      apiGetPageDebug:false ,   // API getPageRecord 除錯模式
      apiGetRtDebug:false ,     // API getRt 除錯模式
      apiGetOneDebug:true ,     // API getOneRecord 除錯模式
      apiPutDebug:false ,       // API 修改 除錯模式
      apiPostDebug:false ,      // API 新增 除錯模式
      apiDeleteDebug:false ,    // API 刪除 除錯模式
      pageMode: true,           // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
  
            apiName:"" ,
      perPage: 15,             //預設15筆
      currEplid: "",             //當前選擇的考卷ID
      ShareFilterData: {},
      ReceiveFilterData: {},
      mode: "Share",
	    version:"",
	    message_switch: false,   
	    push_switch: false,    	
      filterData: {},
      form:{
        seq: 10,
        switch: true,
      },
      title: '',
      content: '',
      btnNameLeft: '',
      btnNameRight: '',
      onFuncLeft: Function,
      onFuncRight: Function,
      isDeleteAccount: false,
    }
  },
  methods: {
    setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
    onBack(){  
      this.$refs.commonLeftRightAlertFunc.onHide();
      if (this.$route.query.backUrl != undefined && this.$route.query.backUrl != "" ) {
        this.$router.push({
          name: this.$route.query.backUrl
        });
      } else if (this.$route.params.backUrl != undefined && this.$route.params.backUrl != "" ) {
        this.$router.push({
          name: this.$route.params.backUrl
        });
      } else {
        this.$router.push({
          name: "Mine"
        });
      }

    }, 
	  
      signOutCallback(){ 
        this.$GlobalData.uid = "";
        this.$GlobalData.user = {};
        this.$router.push(
        {
            name : 'Home',
        });
      },
   onSignOut(){ 
		oltConsole.log("onSignOut");
        this.$JsBridge.callHandler('signVueOut',JSON.stringify({}), this.signOutCallback);
		
	}, 
	 
	  
      getNativeAppVersionCallback(version){	  
		oltConsole.log("getNativeAppVersionCallback");		
		
		if (version != undefined && version != "") {			
			oltConsole.log("getNativeAppVersionCallback version:" + version);
			this.version = version;
      
      this.apiName = "config_get_app_version_list";
      var device = '';
      if(this.$JsBridge.isAndroid()){
        device = 'android';
      }else if(this.$JsBridge.isiOS()){
        device = 'IOS';
      }
		  // oltConsole.log(`[getNativeAppVersionCallback] device ==> ${device}`);
      if(device != ''){
        oAC.getAllRecord(oAC.getApiParam(this), {device: device, switch: 'N'}).then(res =>{
		      // oltConsole.log(`[getNativeAppVersionCallback] res.app_version_list.length ==> ${res.app_version_list.length}`);
          if(res != null &&  res.app_version_list != null && res.app_version_list.length > 0 && res.app_version_list[0]){ 
		        //oltConsole.log(`[getNativeAppVersionCallback] is_delete_account ==> ${res.app_version_list[0]['is_delete_account']}`);
            //oltConsole.log(`[getNativeAppVersionCallback] name ==> ${res.app_version_list[0]['name']}`);
            if(res.app_version_list[0]['is_delete_account'] && res.app_version_list[0]['is_delete_account'] == 'Y'){
              if(res.app_version_list[0]['name'] && res.app_version_list[0]['name'] == this.version){
                this.isDeleteAccount = true;
              }else{
                this.isDeleteAccount = false;
              }
            }else{
              this.isDeleteAccount = false;
            }
          }else{
            this.isDeleteAccount = false;
          }
        }).catch((err)=> {
            oltConsole.log(err);
            this.isDeleteAccount = false;
        });
      }
			//$('#app-version').html(version);
		}
 
      },
   onGetNativeAppVersion(){ 
	//版本設定
	oltConsole.log("版本設定");
        this.$JsBridge.callHandler('getVueNativeAppVersion',JSON.stringify({}), this.getNativeAppVersionCallback);
		
	},  
	
   onMessage_switch(){  
	this.message_switch = !this.message_switch;
	oltConsole.log("1oltConsole:"+this.message_switch);
	//console.log("1console:"+this.message_switch);
    let _switch ='N';
	if(this.message_switch!= null){
		if(this.message_switch == 'Y' || this.message_switch == 'true'|| this.message_switch){
			 _switch ='Y';
		}
		else
		{
			 _switch ='N';
		}
	}
	
	console.log("_switch:"+_switch);
 
	//this.$GlobalData.uid = 7715;
		
		//console.log("apiName:");
	oltConsole.log("apiName:");
		//console.log(this.apiName);
	oltConsole.log(this.apiName);
	
		//console.log("##############:");
	oltConsole.log("##############:");
	
        // 修改使用者message_switch狀態
		this.apiName="put_message_switch_setting/"+this.$GlobalData.uid+"/?uid="+this.$GlobalData.uid+"&message_switch="+_switch;
		this.form['uid'] = this.$GlobalData.uid;
		this.form['message_switch'] = _switch;
		
        oAC.putRecord(oAC.getApiParam(this), {}).then((res)=> {
		//console.log("res:");
	oltConsole.log("res:");
		//console.log(res);
	oltConsole.log(res);
		oltConsole.log(JSON.stringify(res)); 
	/*
          if (res != undefined && res.error != undefined) {
            return;
          } 
		  */
        }).catch((err)=> {
          //console.log("error2" + err);
          oltConsole.log("error2" + err);
          // oltConsole.log(err.response.status);
        });



			
	},  
	
   onPush_switch(){ 
  
	this.push_switch = !this.push_switch;
	oltConsole.log("1oltConsole:"+this.push_switch);
	console.log("1console:"+this.push_switch);
    let _switch ='N';
	if(this.push_switch!= null){
		if(this.push_switch == 'Y' || this.push_switch == 'true' || this.push_switch){
			 _switch ='Y';
		}
		else
		{
			 _switch ='N';
		}
	}
	 
	//this.$GlobalData.uid = 7715;
		
		//console.log("apiName:");
	oltConsole.log("apiName:");
		//console.log(this.apiName);
	oltConsole.log(this.apiName);
	
		//console.log("##############:");
	oltConsole.log("##############:");
	
        // 修改使用者push_switch狀態
		this.apiName="put_push_switch_setting/"+this.$GlobalData.uid+"/?uid="+this.$GlobalData.uid+"&push_switch="+_switch;
		this.form['uid'] = this.$GlobalData.uid;
		this.form['push_switch'] = _switch;
		
        oAC.putRecord(oAC.getApiParam(this), {}).then((res)=> {
		//console.log("res:");
	oltConsole.log("res:");
		//console.log(res);
	//oltConsole.log(res);
  //		oltConsole.log(JSON.stringify(res)); 
	/*
          if (res != undefined && res.error != undefined) {
            return;
          } 
		  */
        }).catch((err)=> {
          console.log("error2" + err);
          oltConsole.log("error2" + err);
          // oltConsole.log(err.response.status);
        });


   
	},   	  			  			  
      changeToWebCallback(data){

      },
      onReadme(){
	  console.log("onReadme");
	  
				this.$router.push(
				{
					name : 'Instructions',
					params : {
						vue : "Setting",    
					}
				});
        //this.$JsBridge.callHandler('openWebView', "/instructions?vue=Setting", this.changeToWebCallback);
      },
      onDeleteAccount(){
        this.apiName="login_update_account_switch/"+this.$GlobalData.uid;
        this.form = {
          switch: 'N',
        };
        oltConsole.log(`[onDeleteAccount] apiName ==> ${this.apiName}`);
        oAC.putRecord(oAC.getApiParam(this), {}).then((res)=> {
	        oltConsole.log("res:");
          this.onSignOut();
        }).catch((err)=> {
          oltConsole.log("err" + err);
        });
      },
      onConfirmDeleteAccount(){
        this.title = "確定要刪除帳號嗎";
        this.content = "刪除此帳號則無法再次登入，若要再重啟此帳號請聯絡客服。";
        this.btnNameLeft = "取消";
        this.btnNameRight = "確定刪除";
        this.onFuncRight = this.onDeleteAccount;
        this.$refs.commonLeftRightAlertFunc.onShow();
      },
      toastCallback(data){},
  },
  created() {  
    if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
  },
  mounted(){ 
    this.$GlobalData.ShareFilter = {};
    this.$JsBridge.registerMethod(this.toastCallback); 
    // from assets/js/plugins/sliding-nav.js 
	
	if (this.$route.params.uid != undefined){ 
				this.$GlobalData.uid = this.$route.params.uid;	
			} 
			if (this.$route.query.uid != undefined){ 
				this.$GlobalData.uid = this.$route.query.uid;
			}
			
			
			if (this.$GlobalData.uid == undefined){ 
				   this.disable = true;				
			}
			 
			 
    initNav(); 
		
   
	var body = document.body;
	body.classList.add("bg-light");
	console.log("user1");
	oltConsole.log("user1");
	this.onGetNativeAppVersion();
	
    if(this.$GlobalData.uid != "" && this.$GlobalData.user.rt.user){	
console.log("user2:");
	oltConsole.log("user2:");
	oltConsole.log(this.$GlobalData.uid );
		
		//oltConsole.log(JSON.stringify(this.$GlobalData.user)); 
		this.message_switch = this.$GlobalData.user.rt.user[0].message_switch;
		this.push_switch = this.$GlobalData.user.rt.user[0].push_switch;		
		oltConsole.log(this.message_switch);
		oltConsole.log(	this.push_switch);
			
console.log("##############:");
	oltConsole.log("##############:");
    
	}
	
      //if(this.$GlobalData.uid != "" && this.$GlobalData.user.rt.user){
        //this.shareCode = this.$GlobalData.user.rt.user[0].share_code;
  }
}
</script>
<style>
.bg-secondary{
}
</style>

<style scoped>
.input_color{
  border: transparent !important;
}
/*隱藏文字區塊的縮放三角形*/
.input_textarea{
  resize: none;
  min-height: 32vw !important;
}
/*隱藏輸入數字的上下箭頭*/
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.hide{
  display:none !important;
}
.form-check-input:focus{outline:none !important;}
.form-check-input{
  -webkit-tap-highlight-color:transparent !important;
}
.form-check-input:focus{
    -webkit-tap-highlight-color:rgba(0,0,0,0) !important;
    -webkit-user-modify:read-write-plaintext-only !important;
}
.form-switch .form-check-input:checked:after {
  outline:none !important;
}
</style>