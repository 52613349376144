<template>
    <div id="CommonLeftRightAlertFunc">
        <div class="modal fade " id="commonLeftRightAlertFunc" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true">
            <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content nicole-modal"> 
                    <div class="modal-header">
                        <h4 class="text-center text-lg fw-bold text-dark mb-0">{{title}}</h4>
                    </div>    
                <div class="modal-body">    
                <div class="text-center">
                <h5 class="text-simbold text-s text-dark mb-0">{{content}}</h5>
                </div>    
                </div>
                <div class="border-top d-flex align-content-center justify-content-between">
                    <div class="w-50 text-center border-end py-3">
                        <h5 class="text-success text-simbold mb-0" @click="onFuncLeft">{{btnnameleft}}</h5>
                    </div>
                    <div class="w-50 text-center py-3">
                    <h5 class="text-success text-simbold mb-0 " @click="onFuncRight">{{btnnameright}}</h5>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CommonLeftRightAlertFunc',    
    props: {
        onfuncleft: Function,
        onfuncright: Function,
        title: String,
        content: String,
        btnnameleft: String,
        btnnameright: String,
    }, 
    methods: {
        onShow(){
            $('#commonLeftRightAlertFunc').modal("show");
        },
        onHide(){
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonLeftRightAlertFunc').modal("hide");
        },
        onFuncLeft(){
            if(this.onfuncleft != null && this.onfuncleft != undefined){
                $(`#commonLeftRightAlertFunc`).on('hidden.bs.modal', () => {
                    $(`#commonLeftRightAlertFunc`).off('hidden.bs.modal');
                    this.onfuncleft();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonLeftRightAlertFunc').modal("hide");
        },
        onFuncRight(){
            if(this.onfuncright != null && this.onfuncright != undefined){
                $(`#commonLeftRightAlertFunc`).on('hidden.bs.modal', () => {
                    $(`#commonLeftRightAlertFunc`).off('hidden.bs.modal');
                    this.onfuncright();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonLeftRightAlertFunc').modal("hide");
        },
    },
    created() {  
    },
    mounted(){  
    }
};

</script>